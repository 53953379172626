    
    
    export function aggWalletData(fullData) {
      let intermResult = []
      for(let i=0;i<fullData.length;i++) {
        let intermIndex = intermResult.findIndex(item => item.wallet == fullData[i].wallet && item.resourceId == fullData[i].resourceId)
        if (intermIndex > -1) {
          if (fullData[i].timestamp > intermResult[intermIndex].timestamp) {
            intermResult.splice(intermIndex,1)
            intermResult.push(fullData[i])
          }
        }
        else {
          intermResult.push(fullData[i])
        }
      }
      var digits = 1000000000
      const summed = intermResult.reduce((acc, cur) => {
        const item = acc.length > 0 && acc.find(({
          wallet, resourceId
        }) => wallet === cur.wallet && resourceId === cur.resourceId)
        if (item) {
          item.dpsReceived = Math.round(item.dpsReceived + parseInt(cur.dpsReceived)/digits)
          item.dpsSpent = Math.round(item.dpsSpent + parseInt(cur.dpsSpent)/digits)
          item.resourcesReceived = item.resourcesReceived + parseInt(cur.resourcesReceived)
          item.resourcesSpent = item.resourcesSpent + parseInt(cur.resourcesSpent)
        } else acc.push({wallet:cur.wallet,dpsReceived:Math.round(parseInt(cur.dpsReceived)/digits),dpsSpent:Math.round(parseInt(cur.dpsSpent)/digits),resourcesSpent:parseInt(cur.resourcesSpent),resourcesReceived:parseInt(cur.resourcesReceived), resourceId: cur.resourceId});
        return acc
      }, [])
      return summed
}

export function aggPoolData(type, poolId, dataset){
  const fixedDigits = 1000000000
  let resPool = dataset.filter(item => item.poolId == poolId)
  const averages = [...resPool.reduce((m, o) => {
    const date = new Date(o.timestamp * 1000); // create a date from time stamp
    const day = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()); // create the day date
      const item = m.get(day.toISOString().slice(0, 10)) || { day: day.toISOString().slice(0, 10), balance: [] }; // get the item from the map by the date string, or create a new one
      if(type == 'quantity')  { item.balance.push(o.resourceBalance) }; // add the balance number
      if(type == 'dps') { item.balance.push(o.dpsBalance / fixedDigits) }
      if(type == 'price') { item.balance.push(o.dpsBalance / fixedDigits / o.resourceBalance) }
      return m.set(day.toLocaleDateString().slice(0, 10), item); // store the item by the time  string
    }, new Map).values()] // spread the map to an array of values
    .map(({ day, balance }) => ({ // map the array to an object
      day,
      balance: balance.reduce((a, b) => a + b) / balance.length // calculate balance average
    }));
    return averages.sort((a,b) => b.day - a.day)
}

export function aggWalletDataDaily(fullData) {
  //var digits = 1000000000
  function formatDate(d)
  {
   let date = new Date(d)
   var dd = date.getDate(); 
   var mm = date.getMonth()+1;
   var yyyy = date.getFullYear(); 
   if(dd<10){dd='0'+dd} 
   if(mm<10){mm='0'+mm};
   return mm+'/'+dd+'/'+yyyy
 }
  let intermResult = []
  for(let i=0;i<fullData.length;i++) {
    let intermIndex = intermResult.findIndex(item => item.wallet == fullData[i].wallet && item.resourceId == fullData[i].resourceId && (new Date(item.timestamp*1000)).toUTCString() == (new Date(fullData[i].timestamp*1000)).toUTCString())
    if (intermIndex > -1) {
      if (fullData[i].dpsReceived > intermResult[intermIndex].dpsReceived || fullData[i].dpsSpent > intermResult[intermIndex].dpsSpent) {
        intermResult.splice(intermIndex,1)
        fullData[i].day = formatDate((new Date(fullData[i].timestamp*1000)).toUTCString())
        intermResult.push(fullData[i])
      }
    }
    else {
      fullData[i].day = formatDate((new Date(fullData[i].timestamp*1000)).toUTCString())
      intermResult.push(fullData[i])
    }
  }
 // console.log(intermResult)
  return intermResult  

}
