import { aggWalletData, aggWalletDataDaily } from "./walletfunc";
const MAX_QUERY_RESULTS = 1000;
const gqlWalletQuery = `query ($first: Int! = 1000, $time_min: BigInt! = 0, $time_max: BigInt! = 0) {
  resourceSwaps(first: $first, where: {timestamp_gt: $time_min, timestamp_lte: $time_max}, orderBy: timestamp, orderDirection: asc ) {
    id
    wallet
    resourceId
    dpsSpent
    dpsReceived
    resourcesSpent
    resourcesReceived
    timestamp
  }
}
`
const gqlSpecificWalletsQuery = `query ($first: Int! = 1000, $wallet: String! = "", $time_min: BigInt! = 0) {
  resourceSwaps(first: $first, where: {timestamp_gt: $time_min, wallet: $wallet}, orderBy: wallet, orderDirection: asc ) {
    wallet
    resourceId
    dpsSpent
    dpsReceived
    timestamp
  }
}
`
const gqCurrentWalletData = `query ($first: Int! = 1000, $firstId: String = "") {
  resourceSwaps(first: $first, where: {timestamp: 0, id_gt:$firstId}) {
    id
    wallet
    resourceId
    dpsSpent
    dpsReceived
    resourcesSpent
    resourcesReceived
  }
}
`
const gqlResPoolsQuery = `query ($first: Int! = 1000, $time: BigInt! = 0) { 
  resourcePools(first: $first, where: {timestamp_gt: $time}, orderBy: timestamp, orderDirection: asc ) {
    id
    poolId
    timestamp
    resourceBalance
    dpsBalance
  }
}
`
export async function currentWalletsQuery() {
  let responseList = [];
  let lastResponse = [];
/*   const delay = ms => new Promise(res => setTimeout(res, ms)); */
  do {
    const variables = {
      first: MAX_QUERY_RESULTS,
      firstId: lastResponse?.length > 0 ? lastResponse[lastResponse.length - 1].id : ""      
    };
    const gqlResponse = await fetch("https://api.thegraph.com/subgraphs/name/deepspacebsc/dps-resources-swap", {
      body: JSON.stringify({
        query: gqCurrentWalletData,
        variables: variables
      }),
      method: "POST",
    });
    const jsonResponse = await gqlResponse.json();
    lastResponse = jsonResponse.data.resourceSwaps;
    responseList = responseList.concat(lastResponse);
/*     await delay(1000); */
  }
  while (lastResponse.length == MAX_QUERY_RESULTS);
  return aggWalletData(responseList);
}
export async function getWalletDetailsQuery(wallet, timestamp_min) {
  let responseList = [];
  let lastResponse = [];
/*   const delay = ms => new Promise(res => setTimeout(res, ms)); */
  do {
    const variables = {
      first: MAX_QUERY_RESULTS,
      wallet: wallet,
      timestamp_min: lastResponse?.length > 0 ? lastResponse[lastResponse.length - 1].timestamp : timestamp_min,
    };
    const gqlResponse = await fetch("https://api.thegraph.com/subgraphs/name/deepspacebsc/dps-resources-swap", {
      body: JSON.stringify({
        query: gqlSpecificWalletsQuery,
        variables: variables
      }),
      method: "POST",
    });
    const jsonResponse = await gqlResponse.json();
    lastResponse = jsonResponse.data.resourceSwaps;
    responseList = responseList.concat(lastResponse);
/*     await delay(1000); */
  }
  while (lastResponse.length == MAX_QUERY_RESULTS);
  return aggWalletDataDaily(responseList);
}
export async function walletsQuery(timestamp_min, timestamp_max) {
  let responseList = [];
  let lastResponse = [];
/*   const delay = ms => new Promise(res => setTimeout(res, ms)); */
  do {
    const variables = {
      first: MAX_QUERY_RESULTS,
      time_min: lastResponse?.length > 0 ? lastResponse[lastResponse.length - 1].timestamp : timestamp_min,
      time_max: timestamp_max
    };
    const gqlResponse = await fetch("https://api.thegraph.com/subgraphs/name/deepspacebsc/dps-resources-swap", {
      body: JSON.stringify({
        query: gqlWalletQuery,
        variables: variables
      }),
      method: "POST",
    });
    const jsonResponse = await gqlResponse.json();
    lastResponse = jsonResponse.data.resourceSwaps;
    responseList = responseList.concat(lastResponse);
/*     await delay(1000); */
  }
  while (lastResponse.length == MAX_QUERY_RESULTS);
  return aggWalletData(responseList);
}

export async function rpoolsQuery(timeIndex) {
  let responseList = [];
  let lastResponse = [];
/*   const delay = ms => new Promise(res => setTimeout(res, ms)); */
  do {
    const variables = {
      first: MAX_QUERY_RESULTS,
      time: lastResponse?.length > 0 ? lastResponse[lastResponse.length - 1].timestamp : getTime(timeIndex)
    };
    const gqlResponse = await fetch("https://api.thegraph.com/subgraphs/name/deepspacebsc/dps-resources-swap", {
      body: JSON.stringify({
        query: gqlResPoolsQuery,
        variables: variables
      }),
      method: "POST",
    });
    const jsonResponse = await gqlResponse.json();
    lastResponse = jsonResponse.data.resourcePools;
    responseList = responseList.concat(lastResponse);
/*     await delay(1000); */
  }
  while (lastResponse.length == MAX_QUERY_RESULTS);
  return responseList;
}

export function getTime(timeIndex) {
  let d = Math.floor(new Date().getTime() / 1000);
  switch (timeIndex) {
    case 0:
      return (d - (24 * 3600 * 7));  
    case 1:  
      return  (d - (24 * 3600 * 14));
    case 2:
      return (d - (24 * 3600 * 30));
    case 3:
      return 0;
    case -1:
      return d;
    case 98:
      return (d - (12 * 3600));       
      case 99:
        return (d - (24 * 3600));    
        case 100:
          return (d - (24 * 3600 * 1.5));                                       
  }   
}