import React, { useCallback, useEffect } from 'react';
import { Loading } from '../components/Loading';

import { rpoolsQuery, walletsQuery } from '../data/graphql';
import { aggPoolData } from '../data/walletfunc'; 
import { useStateContext } from '../contexts/ContextProvider';

const Home = () => {
  const { homePoolData, setHomePoolData, homeDataLoaded, setHomeDataLoaded } = useStateContext()
  const resourcesDict = ['0-Iron','1-Seplium','2-Hydrogen','3-Fenna','4-Bedasine','5-Netherite','6-Xanifarium','7-Promethium']

  const fetchMyApi = useCallback(async () => {
    if(homePoolData == null) { 
      var responseP = await rpoolsQuery(100)
      var ts = Math.floor(new Date().getTime() / 1000);
      var tsYesterday = ts - (24 * 3600);
      var walletD = await walletsQuery(0, tsYesterday)
      var currentWData = await walletsQuery(tsYesterday, ts)
      
      var diffData = []
      for(let i=0;i<currentWData.length;i++){
        let foundIndex = walletD.findIndex( item => item.wallet == currentWData[i].wallet && item.resourceId == currentWData[i].resourceId )
        if (foundIndex > -1) {
          let walletCalc = {
            wallet: currentWData[i].wallet,
            resourceId: currentWData[i].resourceId,
            dpsReceived: currentWData[i].dpsReceived - walletD[foundIndex].dpsReceived,
            dpsSpent: currentWData[i].dpsSpent - walletD[foundIndex].dpsSpent,
            resourcesReceived: currentWData[i].resourcesReceived - walletD[foundIndex].resourcesReceived,
            resourcesSpent: currentWData[i].resourcesSpent - walletD[foundIndex].resourcesSpent
          }
          diffData.push(walletCalc)
        }
        else {       
          diffData.push(currentWData[i])
        }
      }
      let finalDiffData = diffData.reduce((acc, cur) => {
        const item = acc.length > 0 && acc.find(({
          wallet
        }) => wallet === cur.wallet)
        if (item) {
          item.dpsReceived += cur.dpsReceived
          item.dpsSpent += cur.dpsSpent
          item.resourcesReceived += cur.resourcesReceived
          item.resourcesSpent += cur.resourcesSpent
        } else acc.push({wallet:cur.wallet,dpsReceived:cur.dpsReceived,dpsSpent:cur.dpsSpent,resourcesSpent:cur.resourcesSpent,resourcesReceived:cur.resourcesReceived});
        return acc
      }, [])
      setHomePoolData({ resources: responseP, wallets: finalDiffData})
      setHomeDataLoaded(true)
    }
    else {
      setHomeDataLoaded(true)
    }
  }, [])

  useEffect(() => {
    fetchMyApi()
  }, [fetchMyApi])

  const { currentColor } = useStateContext();
  
  return (
    homeDataLoaded ? (
    <div className='mt-12'>
      <div className='flex flex-wrap lg:flex-wrap justify-center'>
       <div className='flex gap-10 flex-wrap justify-center h-fit'>
            <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-fit shadow-2xl'>
              <div className='mt-10 flex gap-10 flex-wrap justify-center'>
                <div className='m-4 pr-10'>
                  <p className='text-center mb-5 text-3xl font-bold'>Last 24H</p>
                  <div className='text-center'>
                    <p>
                      <span className='text-3xl font-semibold'>{ homePoolData.wallets.reduce((acc, obj) => { return obj.dpsReceived > 0 ? acc + obj.dpsReceived : acc + 0 }, 0).toLocaleString(undefined, { maximumFractionDigits: 2 }) }</span>
                    </p>
                    <p className='text-gray-500 mt-1'>
                    <img src="../images/dps.png" className='inline mb-1' style={{height: "18px", width: "18px"}}></img> Received
                    </p>                    
                  </div>
                  <div className='text-center mt-8'>
                    <p>
                      <span className='text-3xl font-semibold'>{ homePoolData.wallets.reduce((acc, obj) => { return obj.dpsSpent > 0 ? acc + obj.dpsSpent : acc + 0 }, 0).toLocaleString(undefined, { maximumFractionDigits: 2 }) }</span>
                    </p>
                    <p className='text-gray-500 mt-1'>
                    <img src="../images/dps.png" className='inline mb-1' style={{height: "18px", width: "18px"}}></img> Spent
                    </p>                    
                  </div>
{/*                   <div className='mt-5'>
                    <SparkLine 
                      currentColor={currentColor}
                      id="line-sparkline"
                      type="Line"
                      height="80px"
                      width="250px"
                      data={SparklineAreaData}
                      color={currentColor}
                    />
                    </div>  */}                 
                </div>
{/*                 <div>
                  <Stacked width="320px" height="360px"/>
                </div> */}
              </div>
            </div>
            <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-fit shadow-2xl'>
              <div className='mt-10 flex gap-10 flex-wrap justify-center'>
                <div className='m-4 pr-10'>
                  <p className='text-center mb-5 text-3xl font-bold'>Top 5 Receivers</p>
                  { homePoolData.wallets.sort((a,b) => b.dpsReceived - a.dpsReceived).slice(0,5).map((item, index) => ( item.dpsReceived > 0 ?
                  <div className='mt-4 mb-4 w-full' key={item.wallet.substring(0,5) + '_received' + index}>
                    <p>
                      <span className='text-2xl text-left pl-8 pr-8 hover:bg-light-gray hover:dark:bg-gray-600 rounded-md cursor-pointer w-1/2' onClick={()=> window.open("https://bscscan.com/address/" + item.wallet, "_blank")}> { item.wallet.substr(0,5) + '...' + item.wallet.substr(item.wallet.length-4,item.wallet.length) }</span> 
                      <span className='text-2xl float-right pl-8 pr-8'>
                        <img src="../images/dps.png" className='inline mb-1 mr-1' style={{height: "18px", width: "18px"}}></img>
                        { item.dpsReceived }</span>
                    </p>                   
                  </div> 
                  : <div key={item.wallet.substring(0,5) + '_received' + index}></div>))}             
                </div>
              </div>
            </div>   
            <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-fit shadow-2xl'>
              <div className='mt-10 flex gap-10 flex-wrap justify-center'>
                <div className='m-4 pr-10'>
                  <p className='text-center mb-5 text-3xl font-bold'>Top 5 Spenders</p>
                  { homePoolData.wallets.sort((a,b) => b.dpsSpent - a.dpsSpent).slice(0,5).map((item, index) => ( item.dpsSpent > 0 ?
                  <div className='mt-4 mb-4 w-full' key={item.wallet.substring(0,5) + '_spent' + index}>
                    <p>
                      <span className='text-2xl text-left pl-8 pr-8 hover:bg-light-gray hover:dark:bg-gray-600 rounded-md cursor-pointer w-1/2' onClick={()=> window.open("https://bscscan.com/address/" + item.wallet, "_blank")}> { item.wallet.substr(0,5) + '...' + item.wallet.substr(item.wallet.length-4,item.wallet.length) }</span> 
                      <span className='text-2xl float-right pl-8 pr-8'>
                        <img src="../images/dps.png" className='inline mb-1 mr-1' style={{height: "18px", width: "18px"}}></img>
                        { item.dpsSpent }</span>
                    </p>                   
                  </div> 
                  : <div key={item.wallet.substring(0,5) + '_spent' + index}></div>))}              
                </div>
              </div>
            </div>                     
      </div>        
        <div className='flex m-3 flex-wrap justify-center gap-1 items-center'>
          {resourcesDict.map((item, index) => (
            <div key={index}
            className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl shadow-2xl w-fit min-w-[20%] mb-5 mr-5'
            >
              <button type="button"
              className="text-2xl rounded-full p-4 hover:drop-shadow-xl">
                <img src={`../images/${index}.png`} className="w-8 h-8" />
                <p className='text-sm text-gray-400 mt-1'>{item}</p>
              </button>
              <p className='mt-3 '>
                <span className='text-lg font-semibold'>
                Qty: {aggPoolData('quantity', index, homePoolData.resources )[1].balance.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </span>
                <span className={`text-sm ml-2 font-semibold w-fit `}
                style={{color: `${((aggPoolData('quantity', index, homePoolData.resources )[1].balance - aggPoolData('quantity', index, homePoolData.resources )[0].balance) / aggPoolData('quantity', index, homePoolData.resources )[0].balance * 100).toFixed(2) > 0 ? 'green' : 'red' }`}}>
                ({((aggPoolData('quantity', index, homePoolData.resources )[1].balance - aggPoolData('quantity', index, homePoolData.resources )[0].balance) / aggPoolData('quantity', index, homePoolData.resources )[0].balance * 100).toFixed(2) }%)
                </span>                
                </p>
              <p className='mt-3'>
                <span className='text-lg font-semibold'>
                Balance: {aggPoolData('dps', index, homePoolData.resources )[1].balance.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </span>
                <span className={`text-sm ml-2 font-semibold w-fit`}
                style={{color: `${((aggPoolData('dps', index, homePoolData.resources )[1].balance - aggPoolData('dps', index, homePoolData.resources )[0].balance) / aggPoolData('dps', index, homePoolData.resources )[0].balance * 100).toFixed(2) > 0 ? 'green' : 'red' }`}}>
                ({((aggPoolData('dps', index, homePoolData.resources )[1].balance - aggPoolData('dps', index, homePoolData.resources )[0].balance) / aggPoolData('dps', index, homePoolData.resources )[0].balance * 100).toFixed(2) }%)
                </span>                 
                </p>
              <p className='mt-3'>
                <span className='text-lg font-semibold'>
                Price: <img src="../images/dps.png" className='inline mb-0.5' style={{height: "18px", width: "18px"}}></img> {aggPoolData('price', index, homePoolData.resources )[1].balance.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </span>
                <span className={`text-sm ml-2 font-semibold w-fit`}
                style={{color: `${((aggPoolData('price', index, homePoolData.resources )[1].balance - aggPoolData('price', index, homePoolData.resources )[0].balance) / aggPoolData('price', index, homePoolData.resources )[0].balance * 100).toFixed(2) > 0 ? 'green' : 'red' }`}}>
                ({((aggPoolData('price', index, homePoolData.resources )[1].balance - aggPoolData('price', index, homePoolData.resources )[0].balance) / aggPoolData('price', index, homePoolData.resources )[0].balance * 100).toFixed(2) }%)
                </span>                                               
              </p>
            </div>
          ))}
        </div>        
      </div>
    </div>
  ) :
  (
    <Loading />
  )
  )
}

export default Home