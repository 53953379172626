import React, { useCallback, useEffect } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Filter, Toolbar, Page, Sort, Search, tooltipDestroy } from '@syncfusion/ej2-react-grids';
import { currentWalletsQuery } from '../data/graphql';
import { walletGrid } from '../data/data';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { Loading } from '../components/Loading';

const Wallets = () => {
  const toolbarOptions = ['Search'];
  const { walletData, setWalletData, dataLoaded, setDataLoaded } = useStateContext();
  
  const fetchMyApi = useCallback(async () => {
    if(walletData == null) {
    let response = await currentWalletsQuery()
    let finalDiffData = response.reduce((acc, cur) => {
      const item = acc.length > 0 && acc.find(({
        wallet
      }) => wallet === cur.wallet)
      if (item) {
        item.dpsReceived += cur.dpsReceived
        item.dpsSpent += cur.dpsSpent
        item.resourcesReceived += cur.resourcesReceived
        item.resourcesSpent += cur.resourcesSpent
      } else acc.push({wallet:cur.wallet,dpsReceived:cur.dpsReceived,dpsSpent:cur.dpsSpent,resourcesSpent:cur.resourcesSpent,resourcesReceived:cur.resourcesReceived});
      return acc
    }, [])    
    setWalletData(finalDiffData)
    setDataLoaded(true)
    }
    else {
      setDataLoaded(true)
    }
  }, [])

  useEffect(() => {
    fetchMyApi()
  }, [fetchMyApi])

  return (
    dataLoaded ? (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Wallet Data" />
      <GridComponent
        dataSource={walletData}
        width="auto"
        allowPaging
        allowSorting
        allowFiltering
        filterSettings={{showFilterBarOperator: true}}
        sortSettings={{ columns: [{field: 'dpsReceived', direction: 'Descending'}]}}
        pageSettings={{ pageCount: 5 }}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {walletGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter, Search, Toolbar]} />
      </GridComponent>
    </div>
    ) :
      (
        <Loading />
      )
  );
};
export default Wallets;