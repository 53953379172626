import React, { useState, useEffect, useCallback } from "react";
import { getWalletDetailsQuery } from '../data/graphql';
import { LineChart } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { Loading } from '../components/Loading';
import { ChartComponent, SeriesDirective, SeriesCollectionDirective, Inject, LineSeries, StackingColumnSeries, Category, Legend, Tooltip, DataLabel } from "@syncfusion/ej2-react-charts";
import { useParams } from "react-router-dom";
import { LinePrimaryXAxis, LinePrimaryYAxis } from "../data/data";

const WalletInfo =  () => {
  const { id } = useParams()
  const wallet = id;
  const { currentColor, currentMode, walletDetailsLoaded , setWalletDetailsLoaded, lineChartData, setLineChartData } = useStateContext();
  const fetchMyDetails = useCallback(async () => {

    let response = await getWalletDetailsQuery(wallet, 0)
    response = response.sort((a,b) => a.timestamp - b.timestamp)
    let diffData = []
    function formatDate(d)
    {
     let date = new Date(d)
     var dd = date.getDate(); 
     var mm = date.getMonth()+1;
     var yyyy = date.getFullYear(); 
     if(dd<10){dd='0'+dd} 
     if(mm<10){mm='0'+mm};
     return mm+'/'+dd+'/'+yyyy
   }
    var digits = 1000000000
    for(let i=0;i<response.length;i++){
        let intermIndex = diffData.findIndex( item => item.resourceId == response[i].resourceId && item.day == response[i].day )
        if (intermIndex > -1) {
          let walletCalc = {
            day: response[i].day,
            resourceId: response[i].resourceId,
            dpsReceived: parseInt(response[i].dpsReceived) > parseInt(diffData[intermIndex].dpsReceived) ? response[i].dpsReceived :  diffData[intermIndex].dpsReceived,
            dpsSpent: parseInt(response[i].dpsSpent) > parseInt(diffData[intermIndex].dpsSpent) ? response[i].dpsSpent : diffData[intermIndex].dpsSpent
        }
        diffData.splice(intermIndex,1)
        diffData.push(walletCalc)
        }
        else {     
            let walletCalc = {
                day: response[i].day,
                resourceId: response[i].resourceId,
                dpsReceived: response[i].dpsReceived,
                dpsSpent: response[i].dpsSpent
            }              
          diffData.push(walletCalc)
        }
      }

    for(let j=0; j<30;j++) {
        let d = Math.floor(new Date().getTime() / 1000) - 24 * 3600 * j;
        let day = formatDate(d*1000)
        for(let k=0; k<8;k++) {
            let foundIndex = diffData.filter(item => item.resourceId == k.toString()).findIndex(item => item.day == day)
            if (foundIndex < 0) {
                let item = {
                    day: day,
                    resourceId: k.toString(),
                    dpsReceived: 0,
                    dpsSpent: 0
                }
                diffData.push(item)
            } 
        }
    }

    let finalDiffData = diffData.reduce((acc, cur) => {
      const item = acc.length > 0 && acc.find(({
        day, resourceId
      }) => day === cur.day && resourceId === cur.resourceId)
      if (item) {
        item.dpsReceived = Math.round(item.dpsReceived + parseInt(cur.dpsReceived)/digits)
        item.dpsSpent = Math.round(item.dpsSpent + parseInt(cur.dpsSpent)/digits)
      } else acc.push({day: cur.day, dpsReceived: Math.round(parseInt(cur.dpsReceived)/digits),dpsSpent: Math.round(parseInt(cur.dpsSpent)/digits), resourceId: cur.resourceId});
      return acc
    }, [])
    let chartData = []

    for(let n=0; n<8;n++) {
        let resourceDataR = finalDiffData.filter(item => item.resourceId == n.toString()).sort((a,b) => (new Date(a.day)).getTime() - (new Date(b.day)).getTime()).map(item => ({x: item.day, y: item.dpsReceived}))
        for(let p=0;p<resourceDataR.length;p++) {
            resourceDataR[p].y = resourceDataR[p].y == 0 ? resourceDataR[p].y = resourceDataR[p-1]?.y || 0 : resourceDataR[p].y 
        }
        chartData.push(resourceDataR.slice(resourceDataR.length-14,resourceDataR.length))
    }
    for(let m=0; m<8;m++) {
        let resourceDataS = finalDiffData.filter(item => item.resourceId == m.toString()).sort((a,b) => (new Date(a.day)).getTime() - (new Date(b.day)).getTime()).map(item => ({x: item.day, y: item.dpsSpent}))
        for(let p=0;p<resourceDataS.length;p++) {
            resourceDataS[p].y = resourceDataS[p].y == 0 ? resourceDataS[p].y = resourceDataS[p-1]?.y || 0 : resourceDataS[p].y 
        }
        chartData.push(resourceDataS.slice(resourceDataS.length-14,resourceDataS.length))
    }    
    let lineCustomSeriesReceived = [
        {
            dataSource: chartData[0],
            xName: 'x',
            yName: 'y',
            name: 'vs. Iron',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line'        
        },
        {
            dataSource: chartData[1],
            xName: 'x',
            yName: 'y',
            name: 'vs. Seplium',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        },
        {
            dataSource: chartData[2],
            xName: 'x',
            yName: 'y',
            name: 'vs. Hydrogen',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[3],
            xName: 'x',
            yName: 'y',
            name: 'vs. Fenna',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        },
        {
            dataSource: chartData[4],
            xName: 'x',
            yName: 'y',
            name: 'vs. Besadine',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[5],
            xName: 'x',
            yName: 'y',
            name: 'vs. Netherite',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[6],
            xName: 'x',
            yName: 'y',
            name: 'vs. Xanifarium',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[7],
            xName: 'x',
            yName: 'y',
            name: 'vs. Promethium',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }                                  
                                
    ]
    let lineCustomSeriesSpent = [
        {
            dataSource: chartData[8],
            xName: 'x',
            yName: 'y',
            name: 'vs. Iron',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line'        
        },
        {
            dataSource: chartData[9],
            xName: 'x',
            yName: 'y',
            name: 'vs. Seplium',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        },
        {
            dataSource: chartData[10],
            xName: 'x',
            yName: 'y',
            name: 'vs. Hydrogen',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[11],
            xName: 'x',
            yName: 'y',
            name: 'vs. Fenna',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        },
        {
            dataSource: chartData[12],
            xName: 'x',
            yName: 'y',
            name: 'vs. Besadine',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[13],
            xName: 'x',
            yName: 'y',
            name: 'vs. Netherite',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[14],
            xName: 'x',
            yName: 'y',
            name: 'vs. Xanifarium',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }, 
        {
            dataSource: chartData[15],
            xName: 'x',
            yName: 'y',
            name: 'vs. Promethium',
            width: '2',
            marker: { 
                dataLabel: {
                    alignment: 'Center',
                    position: 'Top',
                    visible: true
                },
                visible: true, width: 2, height: 2 },
            type: 'Line',        
        }                          
    ]    
    setLineChartData({ lineCustomSeriesReceived: lineCustomSeriesReceived, lineCustomSeriesSpent: lineCustomSeriesSpent })

/*     console.log(lineChartData) */
    setWalletDetailsLoaded(true)
  }, [])

  useEffect(() => {
    fetchMyDetails()
  }, [fetchMyDetails])

    return (
        walletDetailsLoaded ? (
    <div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-full">
          <p className="text-xxl font-bold mb-10">Wallet Address: {wallet}</p>
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">DPS Received</p>
          </div>
          <div className="w-full overflow-auto">
            <ChartComponent
                id="line-chart1"
                height="420px"
                primaryXAxis={LinePrimaryXAxis}
                primaryYAxis={LinePrimaryYAxis}
                chartArea={{ border: { width: 0 } }}
                tooltip={{ enable: true }}
                background={currentMode === 'Dark' ? '#33373E' : '#fff'}
                legendSettings={{ background: 'white' }}
                >
                <Inject services={[LineSeries, Category, Legend, Tooltip, DataLabel]} />
                <SeriesCollectionDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {lineChartData.lineCustomSeriesReceived.map((item, index) => <SeriesDirective key={index} {...item} />)}
                </SeriesCollectionDirective>
            </ChartComponent>
          </div>         
        </div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-full">
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">DPS Spent</p>
          </div>
          <div className="w-full overflow-auto">
            <ChartComponent
                id="line-chart2"
                height="420px"
                primaryXAxis={LinePrimaryXAxis}
                primaryYAxis={LinePrimaryYAxis}
                chartArea={{ border: { width: 0 } }}
                tooltip={{ enable: true }}
                background={currentMode === 'Dark' ? '#33373E' : '#fff'}
                legendSettings={{ background: 'white' }}
                >
                <Inject services={[LineSeries, Category, Legend, Tooltip, DataLabel]} />
                <SeriesCollectionDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {lineChartData.lineCustomSeriesSpent.map((item, index) => <SeriesDirective key={index} {...item} />)}
                </SeriesCollectionDirective>
            </ChartComponent>
          </div> 
        </div>                
    </div>
  )
  :
  (
    <Loading />
  )
    )
}

export default WalletInfo