import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
    userProfile: false
}

export const ContextProvider = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState('#03C9D7');
    const [currentMode, setCurrentMode] = useState('Light')
    const [themeSettings, setThemeSettings] = useState(false)

    const setMode = (e) => {
        setCurrentMode(e.target.value);
        localStorage.setItem('themeMode', e.target.value);
        setThemeSettings(false)
    }

    const setColor = (color) => {
        setCurrentColor(color);
        localStorage.setItem('colorMode', color);
        setThemeSettings(false)
    }

    const [walletData, setWalletData] = useState(null)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [homeWalletData, setHomeWalletData] = useState(null)
    const [homeDataLoaded, setHomeDataLoaded] = useState(false)
    const [homePoolData, setHomePoolData] = useState(null)
    const [walletDetailsLoaded, setWalletDetailsLoaded] = useState(false)
    const [detailsData, setDetailsData] = useState(null)
    const [lineChartData, setLineChartData] = useState(null)
    const handleClick = (clicked) => {
        setIsClicked({...initialState, [clicked]:true}) //spread object 
    } 
    return (
        <StateContext.Provider
            value={{ 
                activeMenu: activeMenu,
                setActiveMenu: setActiveMenu,
                isClicked,
                setIsClicked,
                handleClick,
                screenSize, setScreenSize,
                currentColor, currentMode,
                themeSettings, setThemeSettings,
                setMode, setColor,
                walletData, setWalletData,
                dataLoaded, setDataLoaded,
                homeWalletData, setHomeWalletData, homePoolData, setHomePoolData,
                homeDataLoaded, setHomeDataLoaded,
                walletDetailsLoaded, setWalletDetailsLoaded,
                detailsData, setDetailsData,
                lineChartData, setLineChartData
            }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);