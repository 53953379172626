import React, {useEffect} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings} from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Sidebar, Navbar, Footer, ThemeSettings } from './components';
import { Home, Line, Wallets, Stacked, Bar, Pie, Financial } from './pages';
import WalletInfo from './pages/WalletInfo';

import { useStateContext } from './contexts/ContextProvider';

import './App.css'

const App = () => {
    const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode } = useStateContext(); 
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
        <div className='flex relative dark:bg-main-dark-bg'>
            <div className='fixed right-4 bottom-4' style={{ zIndex: '1000'}}>
                <TooltipComponent content="Settings" position="Top">
                    <button type="button" 
                    onClick={() => setThemeSettings(true)}
                    className='text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white'
                    style={{background: `${currentColor}`, borderRadius: '50%'}}>
                        <FiSettings></FiSettings>
                    </button>
                </TooltipComponent>
            </div>
            {activeMenu ? (
                <div className='w-72 fixed z-10 dark:bg-secondary-dark-bg bg-white'>
                    <Sidebar />
                </div>
            ) :
            (
                <div className='w-0 dark:bg-secondary-dark-bg'>
                    <Sidebar />
                </div>
            )}
            <div className={
                `dark:bg-main-dark-bg bg-main-bg min-h-screen w-full
                ${activeMenu ? 'md:ml-72 ' : 'flex-2'}`
            }>
                <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'>
                    <Navbar />
                </div>

            <div>
                { themeSettings && <ThemeSettings /> }
                <Routes>
                    {/* Dashboard */}
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />

                    {/* Pages */}
                    <Route path="/wallet-data-7" element={<Wallets />} />
                    <Route path="/wallet-info/:id" element={<WalletInfo />} />
                    {/* Charts */}                   
{/*                     <Route path="/line" element={<Line />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/stacked" element={<Stacked />} />
                    <Route path="/financial" element={<Financial />} /> */}

                </Routes>
            </div>
{/*             <Footer /> */}
            </div>
        </div>
        </BrowserRouter>
    </div>
  )
}

export default App